$(document).on('render:products', () => {
  if ($('body').attr('data-action') == 'show') {
    document.querySelector('.back_btn').style.display = 'flex'

    // 弹窗
    let bullet = document.querySelector('.bullet')
    let popbox = document.querySelector('.g_vb')
    let pop_content = document.querySelector('.pop_content')
    bullet.onclick = function () {
      popbox.style.display = 'block'
      document.body.style.overflow = 'hidden';
    }
    popbox.onclick = function () {
      popbox.style.display = 'none'
      document.body.style.overflow = '';
    }
    pop_content.onclick = function (e) {
      e.stopPropagation();
    }

    //右侧状态栏
    let title = document.querySelectorAll('.r_content .r_ul>li');
    let content = document.querySelectorAll('.r_content  ol>li');
    title[0].className = 'active'
    content[0].className = 'active'
    
    title.forEach(function (item, key) {
      item.onclick = function () {
        title.forEach(function (i, k) {
          i.className = '';
          content[k].className = '';
        });
        item.className = 'active';
        content[key].className = 'active';
      }
    })


    // 新开页面跳转
    let a = document.querySelectorAll('.r_content ol a')
    a.forEach(v => {
      v.target = "_blank"
    })

    $(document).on('click', '.menu_head', (event) => {
      const target = event.target
      const li = $(event.target).closest("li")

      if (target.tagName !== 'a') {
        li.toggleClass('active')
      }
    })
  }

  let menu_head=document.querySelector('.active .menu_head span')
  let DNA_Service=document.querySelector('.DNA_Service')
  let Tumor=document.querySelector('.Tumor')
  if(menu_head.innerText.includes('肿瘤')){
    DNA_Service.style.display='none'
    Tumor.style.display='block'
  }else if(menu_head.innerText.includes('鉴定')){
    DNA_Service.style.display='block'
    Tumor.style.display='none'
  }else{
    Tumor.style.display='none'
    DNA_Service.style.display='none'
  }
})