$(document).on('render:technologies', () => {
  // 蓝沙技术
  if($('body').attr('data-action')=='detail'){
  document.querySelector('.back_btn').style.display='flex'
  let title = document.querySelectorAll('.tech_choose li');
  let content = document.querySelectorAll('.choose_con li');

  title.forEach(function (item, key) {
    item.onmouseover = function () {
      title.forEach(function (i, k) {
        i.className = '';
        content[k].className = '';
      });
      item.className = 'active';
      content[key].className = 'active';
    }
  })
    let patentImgs = document.querySelectorAll('.patent_img')
    let bigImg = document.querySelector('.bigImg')
    let g_phont = document.querySelector('.g_phont')
    let g_right = document.querySelector('.g_right')
    patentImgs.forEach((item, index) => {
      item.onclick = function () {
        g_phont.style.display = 'block'
        bigImg.src = item.dataset.egUrl
        document.body.style.overflow = 'hidden'; //禁止滚动
      }
    })
    g_right.onclick = function () {
      g_phont.style.display = 'none'
      document.body.style.overflow = ''; //出现滚动条
    }
  }
    
    // 蓝沙服务
  if($('body').attr('data-action')=='service'){
    document.querySelector('.back_btn').style.display='flex'

    let data = [
      // 省份
      {
        'arr': [{
            name: '辽宁',
            value: []
          },
          {
            name: '吉林',
            value: []
          },
          {
            name: '河北',
            value: ['石家庄服务中心', '邢台服务中心', '衡水服务点', '衡水服务中心', '保定服务中心', '廊坊安次服务点',
              '廊坊广阳服务点',
              '廊坊服务中心', '秦皇岛服务点', '承德服务中心', '秦皇岛服务中心', '唐山服务点', '衡水桃城点(锦绣东城)', '衡水桃城点(永兴路)'
            ]
          },
          {
            name: '河南',
            value: ['郑州服务中心', '商丘服务中心', '开封服务点', '新乡服务点', '新乡红旗区服务点', '新乡牧野区服务点',
              '洛阳涧西服务点',
              '洛阳老城区服务点', '洛阳洛龙区服务点', '洛阳瀍河区服务点', '平顶山新华区服务点', '平顶山湛河区服务点', '南阳卧龙区服务点', '南阳宛城服务点', '灵宝市服务点',
              '安阳服务点(辛村镇)', '安阳服务点(文峰区)'
            ]
          },
          {
            name: '湖北',
            value: ['武汉服务中心','黄冈服务中心', '孝感服务中心', '随州服务中心', '十堰市服务中心', '荆州服务中心', '宜昌服务中心']
          },
          {
            name: '湖南',
            value: ['长沙服务中心', '衡阳服务中心']
          },
          {
            name: '山东',
            value: ['临沂服务中心', '青岛服务中心', '济南服务中心', '济宁服务中心', '菏泽服务中心', '德州服务中心',
              '滨州服务中心',
              '东营服务中心', '潍坊服务中心', '威海服务中心', '日照服务中心', '德州德城区服务点(丹若园)', '德州德城区服务点(新湖大街)'
            ]
          },
          {
            name: '山西',
            value: ['太原服务中心']
          },
          {
            name: '陕西',
            value: ['西安服务中心', '延安服务点', '渭南服务中心', '咸阳渭城服务点', '咸阳秦都服务点', '咸阳服务中心', '延安宝塔区服务点(铭鑫区)', '延安宝塔区服务点(长青路)',
              '延安宝塔区服务点(光华路)'
            ]
          },
          {
            name: '安徽',
            value: ['宣城服务中心', '肥东服务点', '庐阳服务点', '蜀山服务点', '肥西服务点', '蚌埠服务中心']
          },
          {
            name: '浙江',
            value: ['嘉兴服务中心', '绍兴服务中心', '宁波服务中心', '台州服务中心', '温州服务点', '金华服务中心']
          },
          {
            name: '江苏',
            value: ['苏州服务中心','徐州服务中心', '宿迁服务中心', '淮安服务中心', '盐城服务中心', '常州服务中心', '南京服务中心']
          },
  
          {
            name: '福建',
            value: ['福州服务中心', '泉州服务中心', '厦门服务中心', '漳州服务中心']
          },
          {
            name: '广东',
            value: ['深圳服务中心', '韶关服务中心', '惠州服务中心', '东莞服务中心', '中山服务中心', '佛山服务中心', '广州服务中心']
          },
          {
            name: '海南',
            value: []
          },
          {
            name: '四川',
            value: ['凉山彝族自治州服务中心']
          },
          {
            name: '云南',
            value: ['昆明服务中心', '临沧服务中心', '楚雄彝族自治州服务中心', '红河哈尼族彝族自治州服务中心']
          },
          {
            name: '贵州',
            value: ['遵义服务中心', '贵阳服务中心', '贵阳服务中心', '六盘水服务中心', '黔南布依族苗族自治州服务中心']
          },
          {
            name: '青海',
            value: []
          },
          {
            name: '甘肃',
            value: ['兰州服务中心']
          },
          {
            name: '江西',
            value: ['九江服务中心', '南昌服务中心', '上饶服务中心', '赣州服务中心', '吉安服务中心', '宜春服务中心']
          },
          {
            name: '台湾',
            value: []
          },
          {
            name: '黑龙江',
            value: []
          }
        ]
      },
      // 自治区
      {
        'arr': [{
            name: '广西壮族自治区',
            value: ['桂林服务中心', '桂林服务中心', '南宁服务中心']
          },
          {
            name: '宁夏回族自治区',
            value: []
          },
          {
            name: '内蒙古自治区',
            value: []
          },
          {
            name: '新疆维吾尔自治区',
            value: ['新疆喀什服务点']
          },
          {
            name: '西藏自治区',
            value: []
          }
        ]
      },
      // 直辖市
      {
        'arr': [{
            name: '北京市',
            value: ['北京服务中心']
          },
          {
            name: '上海市',
            value: ['浦东新区服务中心']
          },
          {
            name: '天津市',
            value: ['天津服务中心']
          },
          {
            name: '重庆市',
            value: ['重庆服务中心']
          }
        ]
      },
      // 行政区
      {
        'arr': [{
            name: '香港特别行政区',
            value: []
          },
          {
            name: '澳门特别行政区',
            value: []
          }
        ]
      }
    ]
    let spans = document.querySelectorAll('#selectTitle span');
    let selectItem = document.querySelector('#p_selectItem')
    let contents = document.querySelectorAll('#p_selectItem ul li');
    let selectImg = document.querySelector('#p_selectImg')
    let p_select = document.querySelector('#p_select')
  
    // 省份
    let provice = document.querySelector('.provice')
    // 总数
    let count = document.querySelector('.count')
    // 中心列表
    let p_list = document.querySelector('.p_list')
    // 筛选显示
    let screen = document.querySelector('.screen')
  
    //地址栏展开
    let bool = true
    selectImg.onclick = function () {
      if (bool) {
        selectItem.style.display = 'block'
        p_select.style.borderRadius = '0.15rem 0.15rem 0 0'
        bool = false
      } else {
        selectItem.style.display = 'none'
        p_select.style.borderRadius = '1.5rem'
        bool = true
      }
    }
  
    spans.forEach(function (item, key) {
      item.onclick = function () {
        spans.forEach(function (i, k) {
          i.className = '';
          contents[k].className = '';
        });
        item.className = 'active';
        contents[key].className = 'active';
        screen.innerHTML = `<span>${item.innerText} | </span>`
  
  
        // 地址渲染
        let str = data[key].arr.map(v => {
          return `<span>${v.name}</span>`
        }).join('')
        contents[key].innerHTML = str
  
        // 选择地址
        let a = contents[key].querySelectorAll('span')
  
        // value为空禁止选择
        let a_bool = data[key].arr.map((v, i) => {
          if (v.value.length == 0) {
            a[i].style.color = '#d7d7d7'
            a[i].style.cursor='default'
            return false
          } else {
            return true
          }
        })
  
        for (let i = 0; i < a.length; i++) {
          if (a_bool[i]) {
            a[i].onclick = function () {
              for (let i = 0; i < a.length; i++) {
                a[i].className = ''
              }
              screen.innerHTML = `<span>${item.innerText} | ${a[i].innerText}</span>`
              // 下拉框信息
              a[i].className = 'active'
  
              // 右侧省份显示
              provice.innerText = a[i].innerText
              // 右侧服务点显示
              data[key].arr.map(v => {
                if (v.name == a[i].innerText && v.value) {
                  count.innerText = v.value.length //服务点总数
                  let str2 = v.value.map(o => {
                    return ` <span><b>·</b>${o}</span>`
                  }).join('')
                  p_list.innerHTML = str2
                }
              })
            }
          }
        }
      }
  
    })  
  
    //默认显示
    let str1 = data[0].arr.map(v => {
      return `<span>${v.name}</span>`
    }).join('')
    contents[0].innerHTML = str1
  
  
  
    let b = contents[0].querySelectorAll('span')
    // value为空禁止选择
    let b_bool = data[0].arr.map((v, i) => {
      if (v.value.length == 0) {
        b[i].style.color = '#d7d7d7'
        b[i].style.cursor='default'
        return false
      } else {
        return true
      }
    })
    for(let j=0;j<b.length;j++){
      if(b_bool){
        b[j].onclick=function(){
          for(let j=0;j<b.length;j++){
            b[j].className=''
          }
          
          screen.innerHTML = `<span>${spans[0].innerText} | ${b[j].innerText}</span>`
  
          b[j].className='active'
           // 右侧省份显示
           provice.innerText = b[j].innerText
              // 右侧服务点显示
              data[0].arr.map(v => {
                if (v.name == b[j].innerText && v.value) {
                  count.innerText = v.value.length //服务点总数
                  let str2 = v.value.map(o => {
                    return ` <span><b>·</b>${o}</span>`
                  }).join('')
                  p_list.innerHTML = str2
                }
              })
        }
      }
    }
  
    // 默认显示湖北
    data[0].arr.forEach((v,i)=>{
      if(v.name=='湖北'){
        b[i].className='active'
        let str3=v.value.map(o=>{
          return ` <span><b>·</b>${o}</span>`
        }).join('')
        p_list.innerHTML = str3 
      }
    })
  }

})